<template>
  <v-card elevation="2" style="width: 300px;">
    <v-card-title>Sign in</v-card-title>
    <v-form @submit.prevent="doSignIn" validate-on="submit" fast-fail ref="signIn">
      <v-card-text v-if="!isLoading">
            <v-text-field outlined v-model="user.email" :rules="inputs.rules.emailRequired" label="Email" required></v-text-field>
            <v-text-field outlined v-model="user.password" :rules="inputs.rules.simplyRequired" label="Password" type="password" required></v-text-field>
        </v-card-text>
        <v-card-text v-else class="text-center" style="height: 100px;">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-card-text>
      <v-card-actions>
        <span class="caption ma-2">
          <router-link :to="{ name: 'auth-forgot' }">
            Forgot Password?
          </router-link>
        </span>
        <v-spacer></v-spacer>
        <v-btn class="ma-2" color="primary" elevation="2" :loading="isLoading" :disabled="isLoading" @click="doSignIn" type="submit">
          Sign in
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'signin',
  data: () => ({
    isLoading: false,
    user: {
      email: String(),
      password: String()
    }
  }),
  methods: {
    doSignIn () {
      if (this.$refs.signIn.validate()) {
        this.isLoading = true

        this.$store.dispatch('doAuthenticateEmail', {
          email: this.user.email,
          password: this.user.password
        }).then(data => {
          this.$nextTick(() => {
            this.$router.push({ name: data.authHome })
          })
        }).catch(error => {
          try {
            this.$root.$emit('showError', error.response.data.error)
          } catch (_) {
            this.$root.$emit('showError', error.message)
          }
        }).finally(_ => {
          this.isLoading = false
        })
      }
    }
  },
  created () {
    this.doSetDocumentTitle('Employee Portal Sign In', false)
  }
}
</script>
